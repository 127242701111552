import { ChangeEvent, useEffect, useState } from 'react';

import { EmptyMessage, Layout, MenuButton, Page } from '@nimbus-ds/patterns';
import { goTo } from '@tiendanube/nexo/helpers';

import nexo from '../../nexoClient';
import { useTranslation } from 'react-i18next';
import { ConfigButton, TableRowSkeleton } from '../../components';
import { useHistory } from 'react-router-dom';
import {
  ICustomOrderPaginate,
  ICustomOrders,
} from '../../lib/interfaces/customOrder.interface';
import apiInstance from '../../utils/apiUtils';
import {
  Box,
  Button,
  Card,
  Icon,
  IconButton,
  Input,
  Link,
  Pagination,
  Popover,
  Select,
  Table,
  Text,
  Tooltip,
} from '@nimbus-ds/components';
import { formatDate } from '../../utils/formatUtils';
import {
  CodeIcon,
  DownloadIcon,
  DrinkIcon,
  EyeIcon,
  SearchIcon,
} from '@nimbus-ds/icons';
import { IPaginateMeta } from '../../lib/interfaces/paginate.interfaces';

function CustomOrdersPage() {
  const { t } = useTranslation();
  const { push } = useHistory();

  const API_BASE_PATH = `/custom-order`;

  const [loadingDataList, setLoadingDataList] = useState(false);
  const [dataList, setDataList] = useState<ICustomOrders[]>([]);
  const [meta, setMeta] = useState<IPaginateMeta | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchStatus, setSearchStatus] = useState<string>('');

  useEffect(() => {
    getDataList();
  }, []);

  const handleChangeSearch = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value } = event.target;

    if (name === 'q') {
      setSearchQuery(value);
    } else if (name === 'order_status') {
      setSearchStatus(value);
    }
    return false;
  };

  const getDataList = async (
    page = 1,
    q: string | null = null,
    order_status_id: string | null = null,
  ) => {
    setLoadingDataList(true);
    try {
      const filters = [`page=${page}`, `finished=false`];

      if (q) {
        filters.push(`q=${q}`);
      }
      if (order_status_id) {
        filters.push(`order_status_id=${order_status_id}`);
      }

      const result = await apiInstance.get(
        `${API_BASE_PATH}?${filters.join('&')}`,
      );
      const data = result.data as ICustomOrderPaginate;
      setMeta(data.meta);
      setDataList(data.result);
    } catch (error) {
      console.log(error);
    }
    setLoadingDataList(false);
  };

  const handleCustomOrderDownload = async (donwload_type: 'xlsx' | 'csv') => {
    try {
      const result = await apiInstance.get(
        `${API_BASE_PATH}/download?type=${donwload_type}`,
        { responseType: 'blob' },
      );
      if (result.status === 200) {
        const blob = await result.data;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download =
          donwload_type === 'xlsx' ? 'custom_orders.xlsx' : 'custom_orders.csv';
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const goToDetail = (item: ICustomOrders) => {
    push(`/custom-orders/${item._id}`);
  };

  const searchList = () => {
    getDataList(1, searchQuery, searchStatus);
  };

  return (
    <>
      <Page>
        <Page.Header
          title={t('CustomOrdersPage.title')}
          buttonStack={
            <Box display="flex" gap="3">
              <Popover
                enabledHover={true}
                arrow={false}
                position="bottom-end"
                zIndex="100"
                content={
                  <Box width="100%" display="grid" gap="2">
                    <MenuButton
                      label="Excel"
                      startIcon={CodeIcon}
                      onClick={() => handleCustomOrderDownload('xlsx')}
                      active={false}
                    />
                    <MenuButton
                      label=".CSV"
                      startIcon={CodeIcon}
                      onClick={() => handleCustomOrderDownload('csv')}
                      active={false}
                    />
                  </Box>
                }
              >
                <Button appearance="primary">
                  <Icon source={<DownloadIcon />} color="currentColor" />
                  Baixar planilha
                </Button>
              </Popover>
              <ConfigButton />
            </Box>
          }
        />
        <Page.Body>
          <Layout columns="1">
            <Box display="grid" gap="4">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap="4"
              >
                <Input.Search
                  placeholder={`Busque por ID do pedido, nome, email ou telefone do cliente ou status do pedido`}
                  name="q"
                  onChange={handleChangeSearch}
                  value={searchQuery}
                />
                <Select
                  appearance="neutral"
                  id="order_status"
                  name="order_status"
                  onChange={handleChangeSearch}
                  value={searchStatus}
                >
                  <Select.Option label="Todas" selected value="" />
                  <Select.Option
                    label="Arte em desenvolvimento"
                    value="64e681d64b12c4926ae77d2c"
                  />
                  <Select.Option
                    label="Pagamento Aprovado"
                    value="64e681d64b12c4926ae77d2b"
                  />
                  <Select.Option
                    label="Pedido em produção"
                    value="64e681d64b12c4926ae77d2d"
                  />
                  <Select.Option
                    label="Entregue"
                    value="64e681d64b12c4926ae77d30"
                  />
                  <Select.Option
                    label="Pedido despachado"
                    value="64e681d64b12c4926ae77d2e"
                  />
                  <Select.Option
                    label="Pedido em transito"
                    value="64e681d64b12c4926ae77d2f"
                  />
                </Select>
                <Button appearance="neutral" onClick={searchList}>
                  Filtrar <Icon color="currentColor" source={<SearchIcon />} />
                </Button>
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap="4"
              ></Box>
              {dataList.length || loadingDataList ? (
                <>
                  <Table>
                    <Table.Head>
                      <Table.Row>
                        <Table.Cell as="th" width="110px">
                          {t('LastsOrdersInProgressListPage.list.table.title1')}
                        </Table.Cell>
                        <Table.Cell as="th" width="110px">
                          Nro. Pedido
                        </Table.Cell>
                        <Table.Cell as="th" width="250px">
                          {t('LastsOrdersInProgressListPage.list.table.title2')}
                        </Table.Cell>
                        <Table.Cell as="th">
                          {t('LastsOrdersInProgressListPage.list.table.title3')}
                        </Table.Cell>
                        <Table.Cell as="th" width="170px">
                          {t('LastsOrdersInProgressListPage.list.table.title4')}
                        </Table.Cell>
                        <Table.Cell as="th" width="50px">
                          {t('LastsOrdersInProgressListPage.list.table.title5')}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {loadingDataList ? (
                        <TableRowSkeleton rowQty={3} cellQty={5} />
                      ) : (
                        dataList.map((item, index) => (
                          <Table.Row key={index}>
                            <Table.Cell>
                              <Tooltip
                                content={t(
                                  'LastsOrdersInProgressListPage.list.table.body.tooltip_open_order',
                                )}
                                position="right"
                              >
                                <Link
                                  as="button"
                                  onClick={() =>
                                    goTo(nexo, `/orders/${item.ns_order_id}`)
                                  }
                                  appearance="primary"
                                >
                                  {item.ns_order_id}
                                </Link>
                              </Tooltip>
                            </Table.Cell>
                            <Table.Cell>{item.ns_order_number}</Table.Cell>
                            <Table.Cell>
                              <Text fontWeight="bold">
                                {item.custom_status_name}
                              </Text>
                            </Table.Cell>
                            <Table.Cell>
                              <Tooltip
                                content={t(
                                  'LastsOrdersInProgressListPage.list.table.body.tooltip_open_customer',
                                )}
                                position="right"
                              >
                                <Link
                                  as="button"
                                  onClick={() =>
                                    goTo(
                                      nexo,
                                      `/customers/${item.ns_customer_id}`,
                                    )
                                  }
                                  appearance="primary"
                                >
                                  {item.ns_customer_name}
                                </Link>
                              </Tooltip>
                            </Table.Cell>
                            <Table.Cell>
                              {formatDate(`${item.updatedAt}`)}
                            </Table.Cell>
                            <Table.Cell>
                              <Box display="flex" justifyContent="center">
                                <Tooltip
                                  content={t(
                                    'LastsOrdersInProgressListPage.list.table.body.tooltip_show_detail',
                                  )}
                                  position="left"
                                >
                                  <IconButton
                                    onClick={() => {
                                      goToDetail(item);
                                    }}
                                    size="1.8rem"
                                    source={<EyeIcon />}
                                  />
                                </Tooltip>
                              </Box>
                            </Table.Cell>
                          </Table.Row>
                        ))
                      )}
                    </Table.Body>
                  </Table>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {meta ? (
                      <>
                        <Text>{meta.total} itens</Text>
                        <Pagination
                          onPageChange={getDataList}
                          activePage={meta.page}
                          pageCount={meta.pages}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </Box>
                </>
              ) : (
                <Card>
                  <Card.Body>
                    <EmptyMessage
                      title={t(
                        'LastsOrdersInProgressListPage.list.empty.title',
                      )}
                      text={`${t(
                        'LastsOrdersInProgressListPage.list.empty.text',
                      )}`}
                      icon={<DrinkIcon size={32} />}
                      actions={null}
                    />
                  </Card.Body>
                </Card>
              )}
            </Box>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
}

export default CustomOrdersPage;
