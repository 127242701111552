export enum FieldTypeOptions {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  SELECT = 'select',
  FILE = 'file',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  USE_TERM = 'use_term',
}

export interface IBriefingFormField {
  _id?: string;
  store_id: number;
  order: number;
  active: boolean;
  required: boolean;
  key: string;
  field_type: FieldTypeOptions;
  label: string;
  placeholder?: string;
  helper_text?: string;
  options?: { icon: string; value: string }[];
  createdAt?: string;
  updatedAt?: string;
}

export const BriefingFormFieldEmpty: IBriefingFormField = {
  store_id: -1,
  order: 1,
  key: '',
  required: false,
  active: true,
  field_type: FieldTypeOptions.TEXT,
  label: '',
  placeholder: '',
  helper_text: '',
  options: [],
};
