import { useEffect, useState } from 'react';

import { DataList, Layout, Page } from '@nimbus-ds/patterns';
import { goTo, navigateHeader } from '@tiendanube/nexo/helpers';

import nexo from '../../nexoClient';
import { useTranslation } from 'react-i18next';
import { ConfigButton } from '../../components';
import { useParams } from 'react-router-dom';
import { ICustomOrders } from '../../lib/interfaces/customOrder.interface';
import apiInstance from '../../utils/apiUtils';
import {
  Accordion,
  Alert,
  Box,
  Card,
  Icon,
  Link,
  Sidebar,
  Table,
  Tag,
  Text,
  Thumbnail,
  Title,
} from '@nimbus-ds/components';
import {
  CheckCircleIcon,
  ExternalLinkIcon,
  ForbiddenIcon,
  MailIcon,
  WhatsappIcon,
} from '@nimbus-ds/icons';
import { formatDate } from '../../utils/formatUtils';

function CustomOrderDetailPage() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const API_BASE_PATH = `/custom-order`;

  const [loadingData, setLoadingData] = useState(false);
  const [order, setOrder] = useState<ICustomOrders | null>(null);
  const [openModalBriefing, setOpenModalBriefing] = useState(false);
  const [briefings, setBriefings] = useState<any[]>([]);
  const [openModalReprovedArts, setOpenModalReprovedArts] = useState(false);
  const [reprovedArts, setReprovedArts] = useState<any[]>([]);

  useEffect(() => {
    navigateHeader(nexo, {
      goTo: '/custom-orders',
      text: `${t('general.back')}`,
    });
    getData();
  }, []);

  const getData = async () => {
    setLoadingData(true);
    try {
      const result = await apiInstance.get(`${API_BASE_PATH}/${id}`);
      const data = result.data as ICustomOrders;
      setOrder(data);

      // setOrder({
      //   _id: '65e09c3af37848c3fd16e329',
      //   store_id: 2907105,
      //   ns_order_id: 1441499463,
      //   ns_order_number: 611,
      //   external_order_id: null,
      //   custom_order_status_id: '64e681d64b12c4926ae77d2b',
      //   custom_status_name: 'Pagamento Aprovado',
      //   finished: false,
      //   custom_counts: 1,
      //   custom_counts_approved: 0,
      //   ns_customer_id: 152624736,
      //   ns_customer_name: 'Floripaçai Comércio LTDA Floripaçai Comércio LTDA',
      //   ns_customer_email: 'comercial@parapa.com.BR',
      //   ns_customer_whatsapp: '+554833753043',
      //   createdAt: '2024-02-29T15:01:14.676Z',
      //   updatedAt: '2024-02-29T15:01:14.676Z',
      //   custom_order_itens: [
      //     {
      //       _id: '65e09c3af37848c3fd16e32d',
      //       store_id: 2907105,
      //       custom_orders_id: '65e09c3af37848c3fd16e329',
      //       ns_customer_id: 152624736,
      //       ns_product_id: 195701088,
      //       ns_variant_id: 790394300,
      //       count_art_reproved: 0,
      //       external_product_id: null,
      //       product_name: 'Tampa de Plástico Reta p/ Pote 270ml (500)',
      //       product_image:
      //         'https://acdn.mitiendanube.com/stores/002/907/105/products/tampa-para-pote-de-papel-270ml-54257e1671a7f5fcf717065528169629-1024-1024.png',
      //       art_final_url: null,
      //       custom_order_item_status_id: '64e66e8d4b12c4926ae77d17',
      //       art_sku: '1636159780',
      //       trello_card_id: null,
      //       finished: false,
      //       briefing: null,
      //       briefing_obj: [
      //         {
      //           motivo: 'Clínica Médica',
      //           fundo_embalagem: 'Lisa',
      //           info_contato: '',
      //           cor_copo: 'DOURADO , CREME , PRETO , BRANCO',
      //           preferencias: 'CLASSICO MINIMALISTA',
      //           descricao:
      //             'A Arquitetura da Clinica tem uma visão clássica remetida com lembranças da Grécia antiga então precisa de uma imagens limpa e conceitual',
      //           Instrucoes: '',
      //           termos: 'on',
      //           logo: 'https://www.dropbox.com/scl/fi/t8vb38x1ukkhddlqalha7/LOGO-BELLADORE.png?rlkey=7qodkvjgnbppm71i86bi9asp6&dl=0',
      //           createdAt: '2024-02-15T15:00:07.366+0000',
      //           reproved_msg:
      //             'Prezado cliente, precisamos que as informações de cor sejam enviadas em código CMYK, único sistema de cor usado pela Papello. As cores possuem diversos tons, para que a arte seja confeccionada na cor exata será necessário o envio do mesmo. Gostaríamos de informar que o dourado não sairá 100% fiel a cor por se tratar da junção da cores C ciano, M magenta, Y amarelo e K preto sendo semelhante ao dourado.  Agradecemos pela sua compreensão e colaboração',
      //         },
      //         {
      //           motivo: 'Clínica Médica',
      //           fundo_embalagem: 'Lisa',
      //           info_contato: '',
      //           cor_copo: 'DOURADO 25%  , CREME , PRETO K: 100 , BRANCO',
      //           preferencias: 'CLASSICO MINIMALISTA',
      //           descricao:
      //             'A Arquitetura da Clinica tem uma visão clássica remetida com lembranças da Grécia antiga então precisa de uma imagens limpa e conceitual',
      //           Instrucoes: '',
      //           termos: 'on',
      //           logo: 'https://www.dropbox.com/scl/fi/c6pmp8tkayfuvhnxjvpto/LOGO-BELLADORE-1.png?rlkey=2ln5iqsirdqziq91wnda9q9g3&dl=0',
      //           createdAt: '2024-02-15T18:31:25.365+0000',
      //           reproved_msg:
      //             'Prezado cliente, por gentileza enviar os códigos das cores no sistema CMYK. O dourado e todas as outras cores solicitadas precisam ter todas as inicias CMYK com suas porcentagens informadas. Exemplo: Amarelo C: 0% M: 0% Y: 100% K:0%  Assim, conseguiremos prosseguir conforme solicitado. Agradecemos pela sua compreensão e colaboração.\n',
      //         },
      //         {
      //           motivo: 'Clínica Médica',
      //           fundo_embalagem: 'Lisa',
      //           info_contato: '',
      //           cor_copo: 'DOURADO 25%  , CREME , PRETO K: 100 , BRANCO',
      //           preferencias: 'CLASSICO MINIMALISTA',
      //           descricao:
      //             'A Arquitetura da Clinica tem uma visão clássica remetida com lembranças da Grécia antiga então precisa de uma imagens limpa e conceitual',
      //           Instrucoes: '',
      //           termos: 'on',
      //           logo: 'https://www.dropbox.com/scl/fi/ynncz0s85qoppfl69bvy3/LOGO-BELLADORE-2.png?rlkey=9l567qau6v812ppmtkdb3ap2y&dl=0',
      //           referencia:
      //             'https://www.dropbox.com/scl/fi/ec7p2hi3ylc20zpbdvblt/9C49F105-3A11-4553-B1FC-797B50C61798.pdf?rlkey=8tu73y13mlckmqqr2lpqa7uwh&dl=0',
      //           createdAt: '2024-02-15T20:09:15.398+0000',
      //         },
      //       ],
      //       art_change_detail: [
      //         {
      //           observation_papello: 'Negado',
      //           status: 'review_reproved',
      //           observation_message:
      //             'QUERO A ARTE DO COPO DE 100ML NOS DOIS TAMANHOS',
      //           observation_file:
      //             'https://www.dropbox.com/scl/fi/b7l2cszgqkdpy4lwjgspw/ARTE-BELLADORE.jpg?rlkey=adp2offccoznkzcljhroh4uyr&dl=0',
      //         },
      //         {
      //           observation_message:
      //             'QUERO A ARTE DO COPO DE 100ML NOS DOIS TAMANHOS',
      //           observation_file:
      //             'https://www.dropbox.com/scl/fi/jpcfbehmetu52sq3xq6wa/ARTE-BELLADORE-1.jpg?rlkey=hyyeix6t9ybhlequ2s8fieoux&dl=0',
      //         },
      //       ],
      //       createdAt: '2024-02-29T15:01:14.690Z',
      //       updatedAt: '2024-02-29T15:01:14.690Z',
      //       custom_order_item_status: 'Aguardando briefing',
      //     },
      //     {
      //       _id: '65e09c3af37848c3fd16e32b',
      //       store_id: 2907105,
      //       custom_orders_id: '65e09c3af37848c3fd16e329',
      //       ns_customer_id: 152624736,
      //       ns_product_id: 194257378,
      //       ns_variant_id: 783349559,
      //       count_art_reproved: 0,
      //       external_product_id: null,
      //       product_name: 'Pote de Papel personalizado 270ml (500)',
      //       product_image:
      //         'https://acdn.mitiendanube.com/stores/002/907/105/products/pote-personalizado-de-papel-270ml-2-bbd0db9d446e8ab15e17072589899599-1024-1024.png',
      //       art_final_url: null,
      //       custom_order_item_status_id: '64e66e8e4b12c4926ae77d18',
      //       art_sku: '1636159569',
      //       trello_card_id: null,
      //       finished: false,
      //       briefing: null,
      //       briefing_obj: [
      //         {
      //           motivo:
      //             'vendas, gostaria de fazer o copo com a logo da marca, poderia fazer o copo roxo, preciso colocar tbm a nossa tabela nutricional',
      //           fundo_embalagem: 'Estampada',
      //           info_contato:
      //             'TELEFONE  WHATS (48) 99107-1642  (48)3206-3360  PRODUZIDO POR FLORIPACAI COMERCIO LTDA CNPJ:22.401.572/0001-15  DISTRIBUIDO POR: M3 DISTRIBUICAO DE POLPA DE FRUTAS E CONGELADOS LTDA. CNPJ:51.317.324/0001-99   AÇAI PREMIUM PRECISA INCLUIR A TABELA NUTRICIONAL DO PRODUTO',
      //           cor_copo: 'roxo',
      //           preferencias: 'VIBRANTE E COLORIDA',
      //           descricao: 'QUERO UMA EMBALAGEM QUE CHAME ATENÇÃO DO CLIENTE',
      //           Instrucoes: '',
      //           termos: 'on',
      //           logo: 'https://www.dropbox.com/scl/fi/9iv071ivbjiftow3bqoht/ID-visual-M3-A-a-ELIPSE.pdf?rlkey=gihllxtm08ymxx8is4rtsk3gw&dl=0',
      //           createdAt: '2024-02-29T15:19:50.253Z',
      //         },
      //       ],
      //       art_change_detail: [],
      //       createdAt: '2024-02-29T15:01:14.684Z',
      //       updatedAt: '2024-02-29T15:19:55.727Z',
      //       custom_order_item_status: 'Briefing em validação',
      //     },
      //   ],
      // });
    } catch (error) {
      console.log(error);
    }
    setLoadingData(false);
  };

  const prepareWhatsappLink = (number: string): string => {
    const cleanedNumber = number.replace(/[()\s-]/g, ''); // Remove parênteses, espaços e hífens
    const formattedNumber = cleanedNumber.startsWith('+')
      ? cleanedNumber
      : `+55${cleanedNumber}`;
    return `https://api.whatsapp.com/send?phone=${formattedNumber}`;
  };

  const handleOpenModalBriefing = (briefings: any) => {
    setBriefings(briefings);
    setOpenModalBriefing(true);
  };

  const handleOpenModalReprovedArts = (reprovedArts: any) => {
    setReprovedArts(reprovedArts);
    setOpenModalReprovedArts(true);
  };

  return (
    <>
      <Page>
        <Page.Header
          title={`${t('CustomOrderDetailPage.title')} ${
            order?.ns_order_number || ''
          }`}
          buttonStack={<ConfigButton />}
        />
        <Page.Body>
          <Layout columns="1">
            {loadingData ? (
              <Title>Carregando</Title>
            ) : (
              <>
                {order ? (
                  <Box display="grid" gap="6">
                    <Layout columns="2 - symmetric">
                      <Card>
                        <Card.Header title="Dados do Pedido" />
                        <Card.Body>
                          <Box display="grid" gap="2">
                            <Box display="flex" gap="2">
                              <Box width="70px">
                                <Text fontWeight="bold">ID:</Text>
                              </Box>
                              <Text>#{order.ns_order_id} </Text>
                              <Link
                                as="a"
                                onClick={() =>
                                  goTo(nexo, `/orders/${order.ns_order_id}`)
                                }
                                appearance="primary"
                                title={`Visualizar pedido original`}
                              >
                                <Icon
                                  color="currentColor"
                                  source={<ExternalLinkIcon />}
                                />
                              </Link>
                            </Box>
                            <Box display="flex" gap="2">
                              <Box width="70px">
                                <Text fontWeight="bold">Número:</Text>
                              </Box>
                              <Text>{order.ns_order_number || '---'}</Text>
                            </Box>
                            <Box display="flex" gap="2">
                              <Box width="70px">
                                <Text fontWeight="bold">Situação:</Text>
                              </Box>
                              <Text>{order.custom_status_name || '---'}</Text>
                            </Box>
                            <Box display="flex" gap="2">
                              <Box width="70px">
                                <Text fontWeight="bold">Finalizado:</Text>
                              </Box>
                              {order.finished ? (
                                <Text color="success-interactive">Sim</Text>
                              ) : (
                                <Text color="danger-interactive">Não</Text>
                              )}
                            </Box>
                            <Box display="flex" gap="4">
                              <Box width="60px">
                                <Text fontWeight="bold">Produtos:</Text>
                              </Box>
                              <Text>
                                {order.custom_order_itens?.length || 1}
                              </Text>
                            </Box>
                          </Box>
                        </Card.Body>
                      </Card>
                      <Card>
                        <Card.Header title="Dados do Cliente" />
                        <Card.Body>
                          <Box display="grid" gap="2">
                            <Box display="flex" gap="2">
                              <Box width="70px">
                                <Text fontWeight="bold">ID:</Text>
                              </Box>
                              <Text>#{order.ns_customer_id} </Text>
                              <Link
                                as="a"
                                onClick={() =>
                                  goTo(
                                    nexo,
                                    `/customers/${order.ns_customer_id}`,
                                  )
                                }
                                appearance="primary"
                                title={`Visualizar dados do cliente original`}
                              >
                                <Icon
                                  color="currentColor"
                                  source={<ExternalLinkIcon />}
                                />
                              </Link>
                            </Box>
                            <Box display="flex" gap="2">
                              <Box width="70px">
                                <Text fontWeight="bold">Nome:</Text>
                              </Box>
                              <Text>{order.ns_customer_name || '---'}</Text>
                            </Box>
                            <Box display="flex" gap="2">
                              <Box width="70px">
                                <Text fontWeight="bold">E-mail:</Text>
                              </Box>
                              <Text>
                                {order.ns_customer_email ? (
                                  <Box
                                    display="flex"
                                    gap="2"
                                    alignItems="center"
                                  >
                                    {order.ns_customer_email}{' '}
                                    <Link
                                      as="a"
                                      href={`mailto:${order.ns_customer_email}`}
                                      appearance="primary"
                                      title={`Enviar e-mail para: ${order.ns_customer_email}`}
                                    >
                                      <Icon
                                        color="currentColor"
                                        source={<MailIcon />}
                                      />
                                    </Link>
                                  </Box>
                                ) : (
                                  '---'
                                )}
                              </Text>
                            </Box>
                            <Box display="flex" gap="2">
                              <Box width="70px">
                                <Text fontWeight="bold">Whatsapp:</Text>
                              </Box>
                              <Text>
                                {order.ns_customer_email ? (
                                  <Box
                                    display="flex"
                                    gap="2"
                                    alignItems="center"
                                  >
                                    {order.ns_customer_whatsapp}{' '}
                                    <Link
                                      as="a"
                                      href={prepareWhatsappLink(
                                        order.ns_customer_whatsapp,
                                      )}
                                      appearance="primary"
                                      title={`Chamar no whatsapp: ${order.ns_customer_whatsapp}`}
                                    >
                                      <Icon
                                        color="currentColor"
                                        source={<WhatsappIcon />}
                                      />
                                    </Link>
                                  </Box>
                                ) : (
                                  '---'
                                )}
                              </Text>
                            </Box>
                          </Box>
                        </Card.Body>
                      </Card>
                    </Layout>

                    <Card padding="none">
                      <Card.Header
                        padding="base"
                        title="Produtos Personalizados"
                      />
                      <Card.Body>
                        {order.custom_order_itens?.length ? (
                          <DataList>
                            {order.custom_order_itens.map((item, i) => (
                              <DataList.Row key={i} gap="1">
                                <Box display="flex" gap="4">
                                  {item.product_image ? (
                                    <Thumbnail
                                      src={item.product_image}
                                      alt={item.product_name}
                                      width="120px"
                                    />
                                  ) : (
                                    <Thumbnail
                                      alt={item.product_name}
                                      width="120px"
                                    />
                                  )}
                                  <Box
                                    flex="1"
                                    display="flex"
                                    justifyContent="space-between"
                                  >
                                    <Box display="grid" gap="2">
                                      <Tag appearance="warning">
                                        {item.custom_order_item_status || '---'}
                                      </Tag>
                                      <Text
                                        fontWeight="bold"
                                        fontSize="highlight"
                                      >
                                        {item.product_name}
                                      </Text>
                                      <Box display="flex" gap="2">
                                        <Text>SKU da Arte:</Text>
                                        <Text
                                          fontWeight="bold"
                                          color="primary-interactive"
                                        >
                                          {item.art_sku}
                                        </Text>
                                        {item.art_final_url &&
                                        item.art_final_url !== '' ? (
                                          <Link
                                            as="a"
                                            appearance="primary"
                                            href={item.art_final_url}
                                            title="Abrir arte final"
                                            target="_blank"
                                          >
                                            Visualizar arte final
                                            <Icon
                                              color="currentColor"
                                              source={<ExternalLinkIcon />}
                                            />
                                          </Link>
                                        ) : (
                                          <></>
                                        )}
                                      </Box>

                                      {item.briefing_obj?.length ? (
                                        <Link
                                          appearance="primary"
                                          onClick={() =>
                                            handleOpenModalBriefing(
                                              item.briefing_obj,
                                            )
                                          }
                                        >
                                          Historico de briefings
                                        </Link>
                                      ) : (
                                        <Text>Nenhuma briefing</Text>
                                      )}
                                      {item.art_change_detail?.length ? (
                                        <Link
                                          appearance="primary"
                                          onClick={() =>
                                            handleOpenModalReprovedArts(
                                              item.art_change_detail,
                                            )
                                          }
                                        >
                                          Historico de alterações
                                        </Link>
                                      ) : (
                                        <Text>
                                          Nenhuma solicitação de alteração
                                        </Text>
                                      )}
                                    </Box>
                                    <Text textAlign="center">
                                      <Box as="b" display="block">
                                        Ultima atualização
                                      </Box>
                                      {formatDate(`${item.updatedAt}`)}
                                    </Text>
                                  </Box>
                                </Box>
                              </DataList.Row>
                            ))}
                          </DataList>
                        ) : (
                          <></>
                        )}
                      </Card.Body>
                    </Card>
                  </Box>
                ) : (
                  <Title>Não encontrado</Title>
                )}
              </>
            )}
          </Layout>
        </Page.Body>
      </Page>
      <Sidebar
        maxWidth="60%"
        open={openModalBriefing}
        onRemove={() => setOpenModalBriefing(false)}
      >
        <Sidebar.Header padding="base" title="Historico de Briefing" />
        <Sidebar.Body padding="none">
          <Accordion>
            {briefings.map((briefing, i) => (
              <Accordion.Item index={`${i}`} key={i}>
                <Accordion.Header
                  borderTop="none"
                  title={`Briefing ${i + 1} ${
                    'reproved_msg' in briefing ? ' - Reprovado' : ''
                  }`}
                  subtitle={`Ultima atualização em ${formatDate(
                    `${
                      briefing.updatedAt
                        ? briefing.updatedAt
                        : briefing.createdAt
                    }`,
                  )}`}
                >
                  <Box
                    backgroundColor={
                      'reproved_msg' in briefing
                        ? 'danger-surface'
                        : 'primary-surface'
                    }
                    borderRadius="5"
                    marginRight="2-5"
                    padding="2-5"
                  >
                    <Icon
                      color="neutral-textHigh"
                      source={
                        'reproved_msg' in briefing ? (
                          <ForbiddenIcon size="medium" />
                        ) : (
                          <CheckCircleIcon size="medium" />
                        )
                      }
                    />
                  </Box>
                </Accordion.Header>
                <Accordion.Body>
                  <Box display="grid" gap="4">
                    {'reproved_msg' in briefing ? (
                      <Alert
                        appearance="danger"
                        title="Briefing rejeitado pelo seguinte motivo:"
                      >
                        {`"${briefing.reproved_msg}"`}
                      </Alert>
                    ) : (
                      <></>
                    )}
                    <Table>
                      <Table.Body>
                        {Object.entries(briefing).map(
                          ([key, value], rowIndex) => (
                            <>
                              {![
                                'createdAt',
                                'updatedAt',
                                'reproved_msg',
                              ].includes(key) ? (
                                <Table.Row key={rowIndex}>
                                  <Table.Cell as="th">{key}</Table.Cell>
                                  <Table.Cell as="th">{value}</Table.Cell>
                                </Table.Row>
                              ) : (
                                <></>
                              )}
                            </>
                          ),
                        )}
                      </Table.Body>
                    </Table>
                  </Box>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Sidebar.Body>
      </Sidebar>
      <Sidebar
        maxWidth="60%"
        open={openModalReprovedArts}
        onRemove={() => setOpenModalReprovedArts(false)}
      >
        <Sidebar.Header padding="base" title="Historico de Briefing" />
        <Sidebar.Body padding="none">
          <Accordion>
            {reprovedArts.map((art, i) => (
              <Accordion.Item index={`${i}`} key={i}>
                <Accordion.Header
                  borderTop="none"
                  title={`Solicitação de alteração da arte ${
                    'observation_papello' in art
                      ? `negada`
                      : art.status === 'review_approved'
                      ? `realiazada`
                      : `aguardando aprovação`
                  }`}
                  subtitle={`Ultima atualização em ${
                    art.updatedAt || art.createdAt
                      ? formatDate(
                          `${art.updatedAt ? art.updatedAt : art.createdAt}`,
                        )
                      : `---`
                  }`}
                >
                  <Box
                    backgroundColor={
                      'observation_papello' in art
                        ? 'danger-surface'
                        : 'primary-surface'
                    }
                    borderRadius="5"
                    marginRight="2-5"
                    padding="2-5"
                  >
                    <Icon
                      color="neutral-textHigh"
                      source={
                        'observation_papello' in art ? (
                          <ForbiddenIcon size="medium" />
                        ) : (
                          <CheckCircleIcon size="medium" />
                        )
                      }
                    />
                  </Box>
                </Accordion.Header>
                <Accordion.Body>
                  <Box display="grid" gap="4">
                    {'observation_papello' in art ? (
                      <Alert
                        appearance="danger"
                        title="Solicitação negada pelo seguinte motivo:"
                      >
                        {`"${art.observation_papello}"`}
                      </Alert>
                    ) : (
                      <></>
                    )}
                    <Table>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell as="th">Arquivo:</Table.Cell>
                          <Table.Cell as="th">
                            {art.observation_file || '---'}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell as="th">Mensagem:</Table.Cell>
                          <Table.Cell as="th">
                            {art.observation_message || '---'}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Box>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Sidebar.Body>
      </Sidebar>
    </>
  );
}

export default CustomOrderDetailPage;
