import { ChangeEvent, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Icon,
  IconButton,
  Input,
  Sidebar,
  Skeleton,
  Spinner,
  Table,
  Tag,
  Text,
  Tooltip,
  useToast,
} from '@nimbus-ds/components';
import {
  BriefingFormGroupEmpty,
  IBriefingFormGroup,
} from '../../lib/interfaces/briefingFormGroup.interfaces';
import apiInstance from '../../utils/apiUtils';
import { EmptyMessage, FormField, InteractiveList } from '@nimbus-ds/patterns';
import {
  AppsListIcon,
  CheckCircleIcon,
  EditIcon,
  ForbiddenIcon,
  LockIcon,
  PlusCircleIcon,
  StopIcon,
  TrashIcon,
} from '@nimbus-ds/icons';
import { TableRowSkeleton } from '../../components';
import { IBriefingFormField } from '../../lib/interfaces/briefingFormFields.interfaces';

function BriefingFormGroupPage() {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const API_BASE_PATH = `/briefing-form-group`;

  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const [loadingDataList, setLoadingDataList] = useState(true);
  const [loadingDataFieldsList, setLoadingDataFieldsList] = useState(true);
  const [formData, setFormData] = useState<IBriefingFormGroup | null>(null);
  const [dataList, setDataList] = useState<IBriefingFormGroup[]>([]);
  const [dataFieldsList, setDataFieldsList] = useState<IBriefingFormField[]>(
    [],
  );
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(false);
  const [sideBarRelationsOpen, setSideBarRelationsOpen] =
    useState<boolean>(false);
  const [qtyActives, setQtyActives] = useState<number>(0);
  const [qtyInactives, setQtyInactives] = useState<number>(0);

  useEffect(() => {
    getDataList();
  }, []);

  const handleChangeData = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value, type } = event.target;

    const helpConfig: any = { ...formData };
    helpConfig[name] =
      type === 'checkbox' ? (event.target as HTMLInputElement).checked : value;
    setFormData({ ...helpConfig });
    return false;
  };

  const handleChangeRelationsData = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    const helpConfig: any = { ...formData };
    if (checked && !helpConfig.form_fields.includes(value)) {
      helpConfig.form_fields.push(value);
    } else {
      const i = helpConfig.form_fields.indexOf(value);
      if (i >= 0) {
        helpConfig.form_fields.splice(i, 1);
      }
    }
    setFormData({ ...helpConfig });
    return false;
  };

  const getDataList = async () => {
    setLoadingDataList(true);
    try {
      const result = await apiInstance.get(API_BASE_PATH);
      const dataList = result.data as IBriefingFormGroup[];

      let actives = 0;
      let inactives = 0;
      for (let x = 0; x < dataList.length; x++) {
        const item = dataList[x];
        if (item.active) {
          actives++;
        } else {
          inactives++;
        }
      }

      setQtyActives(actives);
      setQtyInactives(inactives);
      setDataList(dataList);
    } catch (error) {
      setDataList([]);
    }
    setLoadingDataList(false);
  };

  const getDataFields = async () => {
    setLoadingDataFieldsList(true);
    try {
      const result = await apiInstance.get(
        `/briefing-form-field?fields=_id,key,label`,
      );
      const dataList = result.data as IBriefingFormField[];

      setDataFieldsList(dataList);
    } catch (error) {
      setDataList([]);
    }
    setLoadingDataFieldsList(false);
  };

  const handleRemoveItem = async (id: string) => {
    setLoadingSaveData(true);
    try {
      const result = await apiInstance.delete(`${API_BASE_PATH}/${id}`);
      addToast({
        id: 'remove' + new Date().toISOString(),
        type: 'success',
        position: 4,
        text: `${t('BriefingFormGroupPage.form.alerts.remove_success')}`,
      });
      getDataList();
    } catch (error) {
      addToast({
        id: 'remove' + new Date().toISOString(),
        type: 'danger',
        position: 4,
        text: `${t('BriefingFormGroupPage.form.alerts.remove_error')}`,
      });
    }
    setLoadingSaveData(false);
  };

  const handleOpenSidebarRelations = (
    data: IBriefingFormGroup | null = null,
  ) => {
    if (data === null) {
      setFormData({ ...BriefingFormGroupEmpty });
    } else {
      setFormData({ ...data });
    }
    getDataFields();
    setSideBarRelationsOpen(true);
  };

  const handleOpenSidebarForm = (data: IBriefingFormGroup | null = null) => {
    if (data === null) {
      setFormData({ ...BriefingFormGroupEmpty });
    } else {
      setFormData({ ...data });
    }
    setSideBarOpen(true);
  };

  const handleSubmit = async () => {
    try {
      setLoadingSaveData(true);

      const data = { ...formData };

      let method: 'CREATE' | 'UPDATE' = 'CREATE';
      let id = '';
      if (data._id && data._id !== '') {
        id = `${data._id}`;
        delete data._id;
        delete data.createdAt;
        delete data.updatedAt;
        method = 'UPDATE';
      }
      delete data.store_id;
      delete data.form_fields_objects;
      if (method === 'CREATE') {
        await apiInstance.post(API_BASE_PATH, data);
      } else {
        await apiInstance.put(`${API_BASE_PATH}/${id}`, data);
      }
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'success',
        position: 4,
        text: `${t('BriefingFormGroupPage.form.alerts.save_success')}`,
      });

      setFormData({ ...BriefingFormGroupEmpty });
      setSideBarOpen(false);
      setSideBarRelationsOpen(false);
      getDataList();
    } catch (error) {
      console.log(error);
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'danger',
        position: 4,
        text: `${t('BriefingFormGroupPage.form.alerts.save_error')}`,
      });
    }
    setLoadingSaveData(false);
  };

  return (
    <>
      <Box marginY="10" display="grid" gap="6">
        {dataList.length || loadingDataList ? (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" gap="4">
                <Tag appearance="success">
                  <CheckCircleIcon size={12} />
                  <Text color="success-textLow">
                    {qtyActives}{' '}
                    {qtyActives > 1
                      ? t('BriefingFormGroupPage.form.tags.qty_actives')
                      : t('BriefingFormGroupPage.form.tags.qty_active')}
                  </Text>
                </Tag>

                <Tag appearance="danger">
                  <StopIcon size={12} />
                  <Text color="danger-textLow">
                    {qtyInactives}{' '}
                    {qtyInactives > 1
                      ? t('BriefingFormGroupPage.form.tags.qty_inactives')
                      : t('BriefingFormGroupPage.form.tags.qty_inactive')}
                  </Text>
                </Tag>
              </Box>
              <Button
                appearance="primary"
                onClick={() => handleOpenSidebarForm()}
              >
                <Icon color="currentColor" source={<PlusCircleIcon />} />
                {t('BriefingFormGroupPage.form.ctas.add')}
              </Button>
            </Box>
            <Table>
              <Table.Head>
                <Table.Cell as="th" width="80px">
                  {t('BriefingFormGroupPage.list.table.cell1')}
                </Table.Cell>
                <Table.Cell as="th" width="80px">
                  {t('BriefingFormGroupPage.list.table.cell2')}
                </Table.Cell>
                <Table.Cell as="th">
                  {t('BriefingFormGroupPage.list.table.cell3')}
                </Table.Cell>
                <Table.Cell as="th">
                  {t('BriefingFormGroupPage.list.table.cell4')}
                </Table.Cell>
                <Table.Cell as="th" width="70px">
                  {t('BriefingFormGroupPage.list.table.cell5')}
                </Table.Cell>
                <Table.Cell as="th" width="110px">
                  {t('BriefingFormGroupPage.list.table.cell6')}
                </Table.Cell>
              </Table.Head>
              <Table.Body>
                {loadingDataList ? (
                  <TableRowSkeleton rowQty={3} cellQty={6} />
                ) : (
                  <>
                    {dataList.map((item) => (
                      <Table.Row key={item._id}>
                        <Table.Cell>
                          {item.active ? (
                            <Icon
                              color="success-interactive"
                              source={<CheckCircleIcon size="small" />}
                            />
                          ) : (
                            <Icon
                              color="danger-interactive"
                              source={<StopIcon size="small" />}
                            />
                          )}
                        </Table.Cell>
                        <Table.Cell>{item.order}</Table.Cell>
                        <Table.Cell>{item.title}</Table.Cell>
                        <Table.Cell>{item.description}</Table.Cell>
                        <Table.Cell>
                          <Box as="span" display="flex" justifyContent="center">
                            {item.form_fields_objects?.length || 0}
                          </Box>
                        </Table.Cell>
                        <Table.Cell>
                          <Box display="flex" justifyContent="flex-end" gap="2">
                            <Tooltip
                              content={t(
                                'BriefingFormGroupPage.list.table.cta_add_fields',
                              )}
                              position="top"
                            >
                              <IconButton
                                disabled={loadingSaveData}
                                onClick={() => {
                                  handleOpenSidebarRelations(item);
                                }}
                                size="1.8rem"
                                source={<AppsListIcon />}
                              />
                            </Tooltip>
                            <Tooltip content={t('general.edit')} position="top">
                              <IconButton
                                disabled={loadingSaveData}
                                onClick={() => {
                                  handleOpenSidebarForm(item);
                                }}
                                size="1.8rem"
                                source={<EditIcon />}
                              />
                            </Tooltip>
                            <Tooltip
                              content={t('general.delete')}
                              position="top"
                            >
                              <IconButton
                                disabled={loadingSaveData}
                                onClick={() => {
                                  handleRemoveItem(item._id!);
                                }}
                                size="1.8rem"
                                source={<TrashIcon />}
                              />
                            </Tooltip>
                          </Box>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </>
                )}
              </Table.Body>
            </Table>
          </>
        ) : (
          <EmptyMessage
            title={t('BriefingFormGroupPage.list.empty.title')}
            text={`${t('BriefingFormGroupPage.list.empty.text')}`}
            icon={<ForbiddenIcon size={32} />}
            actions={
              <Button
                as="button"
                appearance="primary"
                onClick={() => {
                  handleOpenSidebarForm();
                }}
              >
                <Icon color="currentColor" source={<PlusCircleIcon />} />
                {t('BriefingFormGroupPage.list.empty.cta')}
              </Button>
            }
          />
        )}
      </Box>
      <Sidebar
        maxWidth="500px"
        padding="base"
        open={sideBarRelationsOpen}
        onRemove={() => {
          setSideBarRelationsOpen(false);
        }}
      >
        <Sidebar.Header
          title={`${t('BriefingFormGroupPage.sidebar.relation.title')}`}
        />
        <Sidebar.Body>
          {formData ? (
            <Box display="grid" gap="6" paddingBottom="4">
              {loadingDataFieldsList ? (
                <TableRowSkeleton rowQty={2} cellQty={2} />
              ) : dataFieldsList && dataFieldsList.length ? (
                <>
                  <Text>
                    {t('BriefingFormGroupPage.sidebar.relation.description')}
                  </Text>
                  <InteractiveList>
                    {dataFieldsList.map((item, i) => (
                      <InteractiveList.CheckboxItem
                        key={i}
                        title={item.label}
                        description={`${t(
                          'BriefingFormGroupPage.sidebar.relation.help_key',
                        )}${item.key}`}
                        checkbox={{
                          name: `fields[]`,
                          value: item._id,
                          checked: formData.form_fields.includes(`${item._id}`)
                            ? true
                            : false,
                          onChange: handleChangeRelationsData,
                        }}
                      ></InteractiveList.CheckboxItem>
                    ))}
                  </InteractiveList>
                </>
              ) : (
                <Alert>
                  {t('BriefingFormGroupPage.sidebar.relation.empty_fields')}
                </Alert>
              )}

              <Button appearance="primary" type="submit" onClick={handleSubmit}>
                <Box as="span" display="flex" alignItems="center" gap="2">
                  {loadingSaveData ? (
                    <Spinner color="currentColor" size="small" />
                  ) : (
                    <Icon
                      color="currentColor"
                      source={<LockIcon size="small" />}
                    />
                  )}
                  {t('BriefingFormGroupPage.sidebar.relation.cta')}
                </Box>
              </Button>
            </Box>
          ) : (
            <Skeleton height="100%" width="9rem" borderRadius="4" />
          )}
        </Sidebar.Body>
      </Sidebar>
      <Sidebar
        maxWidth="500px"
        padding="base"
        open={sideBarOpen}
        onRemove={() => {
          setSideBarOpen(false);
        }}
      >
        <Sidebar.Header title={`${t('BriefingFormGroupPage.sidebar.title')}`} />
        <Sidebar.Body>
          {formData ? (
            <Box display="grid" gap="6" paddingBottom="4">
              <Checkbox
                name="active"
                id="active"
                label={`${t(
                  'BriefingFormGroupPage.sidebar.form.active.label',
                )}`}
                checked={formData.active}
                onChange={handleChangeData}
              />
              <FormField
                label={t('BriefingFormGroupPage.sidebar.form.order.label')}
              >
                <Input
                  type="number"
                  min="1"
                  max="99"
                  step="1"
                  value={formData.order}
                  name="order"
                  onChange={handleChangeData}
                  required={true}
                />
              </FormField>
              <FormField
                label={t('BriefingFormGroupPage.sidebar.form.title.label')}
                helpText={`${t(
                  'BriefingFormGroupPage.sidebar.form.title.help_text',
                )}`}
                showHelpText={true}
              >
                <Input
                  value={formData.title}
                  name="title"
                  onChange={handleChangeData}
                  required={true}
                />
              </FormField>
              <FormField
                label={t(
                  'BriefingFormGroupPage.sidebar.form.description.label',
                )}
                helpText={`${t(
                  'BriefingFormGroupPage.sidebar.form.description.help_text',
                )}`}
                showHelpText={true}
              >
                <Input
                  value={formData.description}
                  name="description"
                  onChange={handleChangeData}
                  required={false}
                />
              </FormField>
              <FormField
                label={t('BriefingFormGroupPage.sidebar.form.help_text.label')}
                helpText={`${t(
                  'BriefingFormGroupPage.sidebar.form.help_text.help_text',
                )}`}
                showHelpText={true}
              >
                <Input
                  value={formData.help_text}
                  name="help_text"
                  onChange={handleChangeData}
                  required={false}
                />
              </FormField>

              <Button appearance="primary" type="submit" onClick={handleSubmit}>
                <Box as="span" display="flex" alignItems="center" gap="2">
                  {loadingSaveData ? (
                    <Spinner color="currentColor" size="small" />
                  ) : (
                    <Icon
                      color="currentColor"
                      source={<LockIcon size="small" />}
                    />
                  )}
                  {t('BriefingFormGroupPage.sidebar.form.cta')}
                </Box>
              </Button>
            </Box>
          ) : (
            <Skeleton height="100%" width="9rem" borderRadius="4" />
          )}
        </Sidebar.Body>
      </Sidebar>
    </>
  );
}

export default BriefingFormGroupPage;
