import React, { ChangeEvent, useEffect, useState } from 'react';

import { EmptyMessage, FormField, Layout, Page } from '@nimbus-ds/patterns';
import { navigateHeader } from '@tiendanube/nexo/helpers';

import nexo from '../../nexoClient';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Card,
  Icon,
  useToast,
  Button,
  Sidebar,
  Table,
  Skeleton,
  IconButton,
  Input,
  Spinner,
} from '@nimbus-ds/components';
import apiInstance from '../../utils/apiUtils';
import { EditIcon, LockIcon, SearchIcon } from '@nimbus-ds/icons';
import { TableRowSkeleton } from '../../components';
import {
  ICustomOrderItemStatus,
  CustomOrderItemStatusActionDescription,
} from '../../lib/interfaces/customOrderItemStatus.interfaces';

function OrderItemsCustomStatusPage() {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [loadingData, setLoadingData] = useState(true);
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const [updateData, setUpdateData] = useState<ICustomOrderItemStatus | null>(
    null,
  );
  const [dataList, setDataList] = useState<ICustomOrderItemStatus[]>([]);
  const [modalUpdateOpen, setModalUpdateOpen] = useState<boolean>(false);

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/configs', text: `${t('general.back')}` });
    getDataList();
  }, []);

  const handleChangeData = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value, type } = event.target;

    const helpNewData: any = { ...updateData };
    helpNewData[name] =
      type === 'checkbox' ? (event.target as HTMLInputElement).checked : value;
    setUpdateData({ ...helpNewData });
    return false;
  };

  const getDataList = async () => {
    try {
      setLoadingData(true);
      const result = await apiInstance.get(`/custom-order-item-status`);
      setDataList(result.data as ICustomOrderItemStatus[]);
    } catch (error) {
      console.log(error);
      setDataList([]);
    }
    setLoadingData(false);
  };

  const handleSubmit = async () => {
    try {
      setLoadingSaveData(true);
      const data = { ...updateData };
      delete data._id;
      delete data.createdAt;
      delete data.updatedAt;

      await apiInstance.put(
        `/custom-order-item-status/${updateData?._id}`,
        data,
      );
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'success',
        position: 4,
        text: `${t('OrderItemsCustomStatusPage.sidebar.form.save_success')}`,
      });

      setUpdateData(null);
      setModalUpdateOpen(false);
      getDataList();
    } catch (error) {
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'danger',
        position: 4,
        text: `${t('OrderItemsCustomStatusPage.sidebar.form.save_error')}`,
      });
    }
    setLoadingSaveData(false);
  };

  const handleOpenModalForm = (item: ICustomOrderItemStatus) => {
    setUpdateData({ ...item });
    setModalUpdateOpen(true);
  };

  const handleCloseModalForm = () => setModalUpdateOpen(false);

  return (
    <>
      <Page maxWidth="60%">
        <Page.Header
          title={t('OrderItemsCustomStatusPage.title')}
          buttonStack={<></>}
        />
        <Page.Body>
          <Layout columns="1">
            <Layout.Section>
              <Box display="grid" gap="10">
                {dataList.length || loadingData ? (
                  <Table>
                    <Table.Head>
                      <Table.Cell as="th" width="40%">
                        {t('OrderItemsCustomStatusPage.list.header.title1')}
                      </Table.Cell>
                      <Table.Cell as="th" width="50%">
                        {t('OrderItemsCustomStatusPage.list.header.title2')}
                      </Table.Cell>
                      <Table.Cell as="th" width="50px">
                        <></>
                      </Table.Cell>
                    </Table.Head>
                    <Table.Body>
                      {dataList.length ? (
                        <>
                          {dataList.map((item) => (
                            <Table.Row key={item._id}>
                              <Table.Cell>{item.name}</Table.Cell>
                              <Table.Cell>
                                {
                                  CustomOrderItemStatusActionDescription[
                                    item.action
                                  ]
                                }
                              </Table.Cell>
                              <Table.Cell>
                                <Box
                                  display="flex"
                                  gap="2"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <IconButton
                                    onClick={() => {
                                      handleOpenModalForm(item);
                                    }}
                                    size="1.8rem"
                                    source={<EditIcon />}
                                  />
                                </Box>
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </>
                      ) : (
                        <TableRowSkeleton rowQty={4} cellQty={4} />
                      )}
                    </Table.Body>
                  </Table>
                ) : (
                  <Card>
                    <Card.Body>
                      <EmptyMessage
                        title={t('OrderItemsCustomStatusPage.list.empty.title')}
                        text={`${t(
                          'OrderItemsCustomStatusPage.list.empty.text',
                        )}`}
                        icon={<SearchIcon size={32} />}
                      />
                    </Card.Body>
                  </Card>
                )}
              </Box>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>

      <Sidebar
        padding="base"
        open={modalUpdateOpen}
        onRemove={handleCloseModalForm}
      >
        <Sidebar.Header
          title={`${t('OrderItemsCustomStatusPage.sidebar.title')}`}
        />
        <Sidebar.Body>
          <Box display="grid" gap="6">
            {updateData ? (
              <FormField
                label={t('OrderItemsCustomStatusPage.sidebar.form.name.label')}
                helpText={`${t(
                  'OrderItemsCustomStatusPage.sidebar.form.name.help_text',
                )}`}
                showHelpText={true}
              >
                <Input
                  value={updateData.name}
                  placeholder="Titulo"
                  name="name"
                  onChange={handleChangeData}
                />
              </FormField>
            ) : (
              <Skeleton height="100%" width="9rem" borderRadius="4" />
            )}
          </Box>
        </Sidebar.Body>
        <Sidebar.Footer>
          <Box display="flex" justifyContent="center">
            <Button appearance="primary" onClick={handleSubmit}>
              <Box as="span" display="flex" alignItems="center" gap="2">
                {loadingSaveData ? (
                  <Spinner color="currentColor" size="small" />
                ) : (
                  <Icon
                    color="currentColor"
                    source={<LockIcon size="small" />}
                  />
                )}
                {t('OrderItemsCustomStatusPage.sidebar.form.cta')}
              </Box>
            </Button>
          </Box>
        </Sidebar.Footer>
      </Sidebar>
    </>
  );
}

export default OrderItemsCustomStatusPage;
