import { ChangeEvent, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Icon,
  IconButton,
  Input,
  Select,
  Sidebar,
  Skeleton,
  Spinner,
  Table,
  Text,
  Tooltip,
  useToast,
} from '@nimbus-ds/components';
import {
  BriefingFormFieldEmpty,
  IBriefingFormField,
} from '../../lib/interfaces/briefingFormFields.interfaces';
import apiInstance from '../../utils/apiUtils';
import { DataList, EmptyMessage, FormField } from '@nimbus-ds/patterns';
import {
  EditIcon,
  ForbiddenIcon,
  LockIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@nimbus-ds/icons';
import { TableRowSkeleton } from '../../components';

function BriefingFormFieldsPage() {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const API_BASE_PATH = `/briefing-form-field`;

  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const [loadingDataList, setLoadingDataList] = useState(true);
  const [formData, setFormData] = useState<IBriefingFormField | null>(null);
  const [dataList, setDataList] = useState<IBriefingFormField[]>([]);
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(false);

  useEffect(() => {
    getDataList();
  }, []);

  const handleChangeData = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value, type } = event.target;

    const helpConfig: any = { ...formData };
    const nameObj = name.split('.');
    if (nameObj.length === 1) {
      helpConfig[name] =
        type === 'checkbox'
          ? (event.target as HTMLInputElement).checked
          : value;
    } else {
      if (nameObj[0] === 'options') {
        helpConfig[nameObj[0]][nameObj[1]][nameObj[2]] =
          type === 'checkbox'
            ? (event.target as HTMLInputElement).checked
            : value;
      }
    }
    setFormData({ ...helpConfig });
    return false;
  };

  const getDataList = async () => {
    setLoadingDataList(true);
    try {
      const result = await apiInstance.get(API_BASE_PATH);
      const dataList = result.data as IBriefingFormField[];

      setDataList(dataList);
    } catch (error) {
      setDataList([]);
    }
    setLoadingDataList(false);
  };

  const handleRemoveItem = async (id: string) => {
    setLoadingSaveData(true);
    try {
      const result = await apiInstance.delete(`${API_BASE_PATH}/${id}`);
      addToast({
        id: 'remove' + new Date().toISOString(),
        type: 'success',
        position: 4,
        text: `${t('BriefingFormFieldsPage.form.alerts.remove_success')}`,
      });
      getDataList();
    } catch (error) {
      addToast({
        id: 'remove' + new Date().toISOString(),
        type: 'danger',
        position: 4,
        text: `${t('BriefingFormFieldsPage.form.alerts.remove_error')}`,
      });
    }
    setLoadingSaveData(false);
  };

  const handleRemoveOption = async (option: any, index: number) => {
    if (formData) {
      const options = formData.options ? formData.options : [];
      if (options[index]) {
        options.splice(index, 1);
        formData.options = options;
        setFormData({ ...formData });
      }
    }
  };

  const handleOpenSidebarForm = (data: IBriefingFormField | null = null) => {
    if (data === null) {
      setFormData({ ...BriefingFormFieldEmpty });
    } else {
      setFormData({ ...data });
    }
    setSideBarOpen(true);
  };

  const handleSubmit = async () => {
    try {
      setLoadingSaveData(true);

      const data = { ...formData };

      let method: 'CREATE' | 'UPDATE' = 'CREATE';
      let id = '';
      if (data._id && data._id !== '') {
        id = `${data._id}`;
        delete data._id;
        delete data.createdAt;
        delete data.updatedAt;
        method = 'UPDATE';
      }
      delete data.store_id;
      console.log(method, data);
      if (method === 'CREATE') {
        await apiInstance.post(API_BASE_PATH, data);
      } else {
        await apiInstance.put(`${API_BASE_PATH}/${id}`, data);
      }
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'success',
        position: 4,
        text: `${t('BriefingFormFieldsPage.form.alerts.save_success')}`,
      });

      setFormData({ ...BriefingFormFieldEmpty });
      setSideBarOpen(false);
      getDataList();
    } catch (error) {
      console.log(error);
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'danger',
        position: 4,
        text: `${t('BriefingFormFieldsPage.form.alerts.save_error')}`,
      });
    }
    setLoadingSaveData(false);
  };

  const handleAddNewOption = () => {
    if (formData) {
      const options = formData.options ? formData.options : [];
      options.push({
        icon: '',
        value: '',
      });
      formData.options = options;
      setFormData({ ...formData });
    }
  };

  return (
    <>
      <Box marginY="10" display="grid" gap="6">
        {dataList.length || loadingDataList ? (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" gap="4"></Box>
              <Button
                appearance="primary"
                onClick={() => handleOpenSidebarForm()}
              >
                <Icon color="currentColor" source={<PlusCircleIcon />} />
                {t('BriefingFormFieldsPage.form.ctas.add')}
              </Button>
            </Box>
            <Table>
              <Table.Head>
                <Table.Cell as="th" width="80px">
                  {t('BriefingFormFieldsPage.list.table.cell1')}
                </Table.Cell>
                <Table.Cell as="th" width="80px">
                  {t('BriefingFormFieldsPage.list.table.cell2')}
                </Table.Cell>
                <Table.Cell as="th" width="280px">
                  {t('BriefingFormFieldsPage.list.table.cell3')}
                </Table.Cell>
                <Table.Cell as="th">
                  {t('BriefingFormFieldsPage.list.table.cell4')}
                </Table.Cell>
                <Table.Cell as="th" width="80px">{``}</Table.Cell>
              </Table.Head>
              <Table.Body>
                {loadingDataList ? (
                  <TableRowSkeleton rowQty={3} cellQty={5} />
                ) : (
                  <>
                    {dataList.map((item) => (
                      <Table.Row key={item._id}>
                        <Table.Cell>{item.order}</Table.Cell>
                        <Table.Cell>{item.key}</Table.Cell>
                        <Table.Cell>{item.field_type}</Table.Cell>
                        <Table.Cell>{item.label}</Table.Cell>
                        <Table.Cell>
                          <Box display="flex" justifyContent="flex-end" gap="2">
                            <Tooltip content={t('general.edit')} position="top">
                              <IconButton
                                disabled={loadingSaveData}
                                onClick={() => {
                                  handleOpenSidebarForm(item);
                                }}
                                size="1.8rem"
                                source={<EditIcon />}
                              />
                            </Tooltip>
                            <Tooltip
                              content={t('general.delete')}
                              position="top"
                            >
                              <IconButton
                                disabled={loadingSaveData}
                                onClick={() => {
                                  handleRemoveItem(item._id!);
                                }}
                                size="1.8rem"
                                source={<TrashIcon />}
                              />
                            </Tooltip>
                          </Box>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </>
                )}
              </Table.Body>
            </Table>
          </>
        ) : (
          <EmptyMessage
            title={t('BriefingFormFieldsPage.list.empty.title')}
            text={`${t('BriefingFormFieldsPage.list.empty.text')}`}
            icon={<ForbiddenIcon size={32} />}
            actions={
              <Button
                as="button"
                appearance="primary"
                onClick={() => {
                  handleOpenSidebarForm();
                }}
              >
                <Icon color="currentColor" source={<PlusCircleIcon />} />
                {t('BriefingFormFieldsPage.list.empty.cta')}
              </Button>
            }
          />
        )}
      </Box>
      <Sidebar
        maxWidth="500px"
        padding="base"
        open={sideBarOpen}
        onRemove={() => {
          setSideBarOpen(false);
        }}
      >
        <Sidebar.Header
          title={`${t('BriefingFormFieldsPage.sidebar.title')}`}
        />
        <Sidebar.Body>
          {formData ? (
            <Box display="grid" gap="6" paddingBottom="4">
              <Checkbox
                name="active"
                id="active"
                label={`${t(
                  'BriefingFormFieldsPage.sidebar.form.active.label',
                )}`}
                checked={formData.active}
                onChange={handleChangeData}
              />
              <Checkbox
                name="required"
                id="required"
                label={`${t(
                  'BriefingFormFieldsPage.sidebar.form.required.label',
                )}`}
                checked={formData.required}
                onChange={handleChangeData}
              />
              <FormField
                label={t('BriefingFormFieldsPage.sidebar.form.order.label')}
              >
                <Input
                  type="number"
                  min="1"
                  max="99"
                  step="1"
                  value={formData.order}
                  name="order"
                  onChange={handleChangeData}
                  required={true}
                />
              </FormField>
              <FormField.Select
                id="field_type"
                name="field_type"
                label={t(
                  'BriefingFormFieldsPage.sidebar.form.field_type.label',
                )}
                onChange={handleChangeData}
              >
                <Select.Option value="text" label="Campo de texto" />
                <Select.Option value="textarea" label="Campo de texto longo" />
                <Select.Option value="select" label="Campo de seleção" />
                <Select.Option value="file" label="Campo de upload" />
                <Select.Option value="checkbox" label="Campo de marcação" />
                <Select.Option value="radio" label="Campo de seleção aberta" />
                <Select.Option value="use_term" label="Campo aceite" />
              </FormField.Select>
              <FormField
                label={t('BriefingFormFieldsPage.sidebar.form.key.label')}
                helpText={`${t(
                  'BriefingFormFieldsPage.sidebar.form.key.help_text',
                )}`}
                showHelpText={true}
              >
                <Input
                  value={formData.key}
                  name="key"
                  onChange={handleChangeData}
                  required={true}
                />
              </FormField>
              <FormField
                label={t('BriefingFormFieldsPage.sidebar.form.label.label')}
                helpText={`${t(
                  'BriefingFormFieldsPage.sidebar.form.label.help_text',
                )}`}
                showHelpText={true}
              >
                <Input
                  value={formData.label}
                  name="label"
                  onChange={handleChangeData}
                  required={true}
                />
              </FormField>
              <FormField
                label={t(
                  'BriefingFormFieldsPage.sidebar.form.placeholder.label',
                )}
                helpText={`${t(
                  'BriefingFormFieldsPage.sidebar.form.placeholder.help_text',
                )}`}
                showHelpText={true}
              >
                <Input
                  value={formData.placeholder}
                  name="placeholder"
                  onChange={handleChangeData}
                  required={false}
                />
              </FormField>
              <FormField
                label={t(
                  'BriefingFormFieldsPage.sidebar.form.helper_text.label',
                )}
                helpText={`${t(
                  'BriefingFormFieldsPage.sidebar.form.helper_text.help_text',
                )}`}
                showHelpText={true}
              >
                <Input
                  value={formData.helper_text}
                  name="helper_text"
                  onChange={handleChangeData}
                  required={false}
                />
              </FormField>

              <Box
                display="grid"
                gap="4"
                borderWidth="none"
                borderTopWidth="1"
                borderBottomWidth="1"
                borderColor="neutral-surfaceHighlight"
                borderStyle="solid"
                paddingY="4"
              >
                <Text fontWeight="bold">
                  {t('BriefingFormFieldsPage.sidebar.form.options.title')}
                </Text>
                {formData.options && formData.options.length ? (
                  <DataList>
                    {formData.options.map((option, i) => (
                      <DataList.Row
                        key={i}
                        gap="1"
                        paddingY="4"
                        paddingX="none"
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          gap="2"
                        >
                          <Box display="flex" gap="2" width="100%">
                            <Input
                              value={option.icon}
                              name={`options.${i}.icon`}
                              onChange={handleChangeData}
                              append={<Text>https://</Text>}
                              appendPosition="start"
                              placeholder={`${t(
                                'BriefingFormFieldsPage.sidebar.form.options.icon_label',
                              )}`}
                              required={false}
                            />
                            <Input
                              value={option.value}
                              name={`options.${i}.value`}
                              onChange={handleChangeData}
                              placeholder={`${t(
                                'BriefingFormFieldsPage.sidebar.form.options.value_label',
                              )}`}
                              required={false}
                            />
                            <Box
                              width="45px"
                              display="flex"
                              justifyContent="flex-end"
                            >
                              <IconButton
                                onClick={() => {
                                  handleRemoveOption(option, i);
                                }}
                                size="2.3rem"
                                source={<TrashIcon />}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </DataList.Row>
                    ))}
                  </DataList>
                ) : (
                  <Alert appearance="neutral">
                    {t(
                      'BriefingFormFieldsPage.sidebar.form.options.empty_message',
                    )}
                  </Alert>
                )}
                <Button
                  type="button"
                  appearance="neutral"
                  onClick={handleAddNewOption}
                >
                  <Icon color="currentColor" source={<PlusCircleIcon />} />
                  {t('BriefingFormFieldsPage.sidebar.form.options.cta_add')}
                </Button>
              </Box>

              <Button appearance="primary" type="submit" onClick={handleSubmit}>
                <Box as="span" display="flex" alignItems="center" gap="2">
                  {loadingSaveData ? (
                    <Spinner color="currentColor" size="small" />
                  ) : (
                    <Icon
                      color="currentColor"
                      source={<LockIcon size="small" />}
                    />
                  )}
                  {t('BriefingFormFieldsPage.sidebar.form.cta')}
                </Box>
              </Button>
            </Box>
          ) : (
            <Skeleton height="100%" width="9rem" borderRadius="4" />
          )}
        </Sidebar.Body>
      </Sidebar>
    </>
  );
}

export default BriefingFormFieldsPage;
