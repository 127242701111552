import { connect, getStoreInfo, iAmReady } from '@tiendanube/nexo/helpers';
import { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import nexo from './nexoClient';
import NexoSyncRoute from './NexoSyncRoute';
import {
  BriefingMainPage,
  ConfigsPage,
  CustomOrderDetailPage,
  CustomOrdersPage,
  MainPage,
  OrderCustomStatusPage,
  OrderItemsCustomStatusPage,
  UnauthorizedPage,
} from './pages';
import { DarkModeProvider, BaseLayout } from './components';

import { ToastProvider } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import CustomOrderItemsPage from './pages/CustomOrderItemsPage';

function App() {
  const [isConnect, setIsConnect] = useState(false);

  const { i18n } = useTranslation();

  useEffect(() => {
    connect(nexo).then(async () => {
      setIsConnect(true);
      iAmReady(nexo);

      const store = await getStoreInfo(nexo);
      i18n.changeLanguage(store.language);
    });
  }, []);

  if (!isConnect) return <div>connecting..</div>;

  return (
    <DarkModeProvider>
      <BrowserRouter>
        <ToastProvider>
          <BaseLayout>
            <NexoSyncRoute>
              <Switch>
                <Route path="/" exact>
                  <MainPage />
                </Route>
                <Route path="/configs">
                  <ConfigsPage />
                </Route>
                <Route path="/register/orders/status">
                  <OrderCustomStatusPage />
                </Route>
                <Route path="/register/orders/items/status">
                  <OrderItemsCustomStatusPage />
                </Route>
                <Route path="/briefings/form">
                  <BriefingMainPage />
                </Route>
                <Route path="/custom-orders" exact>
                  <CustomOrdersPage />
                </Route>
                <Route path="/custom-orders-items" exact>
                  <CustomOrderItemsPage />
                </Route>
                <Route path="/custom-orders/:id">
                  <CustomOrderDetailPage />
                </Route>
                <Route path="/unauthorized" exact>
                  <UnauthorizedPage />
                </Route>
              </Switch>
            </NexoSyncRoute>
          </BaseLayout>
        </ToastProvider>
      </BrowserRouter>
    </DarkModeProvider>
  );
}

export default App;
