import { useEffect } from 'react';

import { Layout, Page } from '@nimbus-ds/patterns';
import { navigateHeader } from '@tiendanube/nexo/helpers';

import nexo from '../../nexoClient';
import { useTranslation } from 'react-i18next';
import { Card, Tabs, Text } from '@nimbus-ds/components';
import BriefingFormGroupPage from './BriefingFormGroupPage';
import BriefingFormFieldsPage from './BriefingFormFieldsPage';

function BriefingMainPage() {
  const { t } = useTranslation();

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/configs', text: `${t('general.back')}` });
  }, []);

  return (
    <>
      <Page maxWidth="80%">
        <Page.Header title={t('BriefingMainPage.title')} buttonStack={<></>} />
        <Page.Body>
          <Layout columns="1">
            <Layout.Section>
              <Tabs fullWidth>
                <Tabs.Item label={`${t('BriefingMainPage.tabs.group.title')}`}>
                  <Card>
                    <Card.Body>
                      <Text fontSize="base">
                        {t('BriefingMainPage.tabs.group.description')}
                      </Text>

                      <BriefingFormGroupPage />
                    </Card.Body>
                  </Card>
                </Tabs.Item>
                <Tabs.Item label={`${t('BriefingMainPage.tabs.field.title')}`}>
                  <Card>
                    <Card.Body>
                      <Text fontSize="base">
                        {t('BriefingMainPage.tabs.field.description')}
                      </Text>

                      <BriefingFormFieldsPage />
                    </Card.Body>
                  </Card>
                </Tabs.Item>
              </Tabs>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
}

export default BriefingMainPage;
