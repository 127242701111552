import { IBriefingFormField } from './briefingFormFields.interfaces';

export interface IBriefingFormGroup {
  _id?: string;
  store_id: number;
  order: number;
  title: string;
  description: string;
  help_text: string;
  active: boolean;
  form_fields: string[];
  form_fields_objects?: IBriefingFormField[];
  createdAt?: string;
  updatedAt?: string;
}

export const BriefingFormGroupEmpty: IBriefingFormGroup = {
  store_id: -1,
  order: 1,
  title: '',
  description: '',
  help_text: '',
  active: true,
  form_fields: [],
};
