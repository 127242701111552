import { ChangeEvent, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  Icon,
  IconButton,
  Input,
  Link,
  Pagination,
  Select,
  Tag,
  Text,
  Thumbnail,
  Tooltip,
} from '@nimbus-ds/components';
import nexo from '../../nexoClient';
import apiInstance from '../../utils/apiUtils';
import { DataList, EmptyMessage, Layout, Page } from '@nimbus-ds/patterns';
import {
  CloseIcon,
  DrinkIcon,
  RemoveFormatIcon,
  SearchIcon,
} from '@nimbus-ds/icons';
import { IMetaPagination } from '../../lib/interfaces/meta-pagination.interfaces';
import { useHistory } from 'react-router-dom';
import { navigateHeader } from '@tiendanube/nexo/helpers';

function CustomOrderItemsListPage() {
  const { t } = useTranslation();
  const { push } = useHistory();

  navigateHeader(nexo, { goTo: '/', text: `${t('general.back')}` });

  const API_BASE_PATH = `/custom-order-item`;

  interface IDataItem {
    _id: string;
    ns_order_id: number;
    ns_order_number: number;
    custom_orders_id: string;
    custom_order_item_status_id: string;
    ns_product_id: number;
    ns_variant_id: number;
    product_name: string;
    product_image: string | null;
    briefing_obj: { [key: string]: string | number | null };
    updatedAt: string;
    ns_customer_name: string;
    custom_status_name: string;
  }

  const [loadingDataList, setLoadingDataList] = useState(false);
  const [dataList, setDataList] = useState<IDataItem[]>([]);
  const [metaPagination, setMetaPagination] = useState<IMetaPagination | null>(
    null,
  );

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchOrderStatus, setSearchOrderStatus] = useState<string>('');
  const [searchItemStatus, setSearchItemStatus] = useState<string>('');

  const handleChangeSearch = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value } = event.target;

    if (name === 'q') {
      setSearchQuery(value);
    } else if (name === 'order_status') {
      setSearchOrderStatus(value);
    } else if (name === 'item_status') {
      setSearchItemStatus(value);
    }
    return false;
  };

  useEffect(() => {
    const page = localStorage.getItem(`custom-order-items-page`)
      ? parseInt(`${localStorage.getItem(`custom-order-items-page`)}`)
      : 1;
    const q = localStorage.getItem(`custom-order-items-search`) || null;
    const order_status =
      localStorage.getItem(`custom-order-items-order-status`) || null;
    const item_status =
      localStorage.getItem(`custom-order-items-item-status`) || null;

    if (q) {
      setSearchQuery(q);
    } else if (order_status) {
      setSearchOrderStatus(order_status);
    } else if (item_status) {
      setSearchItemStatus(item_status);
    }

    getDataList(page, q, order_status, item_status);
  }, []);

  const resetSearch = async () => {
    localStorage.removeItem('custom-order-items-page');
    localStorage.removeItem('custom-order-items-search');
    localStorage.removeItem('custom-order-items-order-status');
    localStorage.removeItem('custom-order-items-item-status');

    setSearchQuery('');
    setSearchOrderStatus('');
    setSearchItemStatus('');

    getDataList();
  };

  const getDataList = async (
    page = 1,
    q: string | null = null,
    custom_order_status_id: string | null = null,
    custom_order_item_status_id: string | null = null,
  ) => {
    setLoadingDataList(true);
    try {
      const filters = [`page=${page}`, `finished=false`];

      localStorage.setItem(`custom-order-items-page`, `${page}`);

      if (q || searchQuery) {
        filters.push(`q_orders=${q ? q : searchQuery}`);
      }
      if (custom_order_status_id || searchOrderStatus) {
        filters.push(
          `custom_order_status_id=${
            custom_order_status_id ? custom_order_status_id : searchOrderStatus
          }`,
        );
      }
      if (custom_order_item_status_id || searchItemStatus) {
        filters.push(
          `custom_order_item_status_id=${
            custom_order_item_status_id
              ? custom_order_item_status_id
              : searchItemStatus
          }`,
        );
      }

      const result = await apiInstance.get(
        `${API_BASE_PATH}?${filters.join('&')}`,
      );
      const data = result.data as {
        meta: IMetaPagination;
        result: IDataItem[];
      };
      setMetaPagination(data.meta);
      setDataList(data.result);
    } catch (error) {
      console.log(error);
    }
    setLoadingDataList(false);
  };

  const order_items_status: { [key: string]: string } = {
    '64e66e8d4b12c4926ae77d17': 'Aguardando briefing',
    '64e66e8e4b12c4926ae77d1a': 'Arte finalizada',
    '64e66e8e4b12c4926ae77d1b': 'Arte reprovada',
    '64e66e8e4b12c4926ae77d18': 'Briefing em validação',
    '64e66e8e4b12c4926ae77d19': 'Desing da arte em desenvolvimento',
    '64f0b35ecebccd05757254fd': 'Briefing rejeitado',
    '64f395a0c9fde33656d1cdfa': 'Arte aprovada',
  };

  const searchList = () => {
    if (searchQuery) {
      localStorage.setItem(`custom-order-items-search`, searchQuery);
    }
    if (searchOrderStatus) {
      localStorage.setItem(
        `custom-order-items-order-status`,
        searchOrderStatus,
      );
    }
    if (searchItemStatus) {
      localStorage.setItem(`custom-order-items-item-status`, searchItemStatus);
    }
    getDataList(1, searchQuery, searchOrderStatus, searchItemStatus);
  };

  const prepareSearchParams = (): string => {
    const filters = [];
    if (searchQuery) {
      filters.push(`q_orders=${searchQuery}`);
    }
    if (searchOrderStatus) {
      filters.push(`custom_order_status_id=${searchOrderStatus}`);
    }
    if (searchItemStatus) {
      filters.push(`custom_order_item_status_id=${searchItemStatus}`);
    }
    return filters.length ? `?` : '' + filters.join('&');
  };

  return (
    <>
      <Page>
        <Page.Header title="Itens personalizados" />
        <Page.Body>
          <Layout columns="1">
            <Box display="grid" gap="4">
              <Text>
                Faça busca dos itens personalizados pelo pedido ou por seu
                status.
              </Text>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap="4"
              >
                <Button appearance="transparent" onClick={resetSearch}>
                  Limpar <Icon color="currentColor" source={<CloseIcon />} />
                </Button>
                <Input.Search
                  placeholder={`Busque por ID do pedido, nome, email ou telefone do cliente ou status do pedido`}
                  name="q"
                  onChange={handleChangeSearch}
                  value={searchQuery}
                />
                <Select
                  appearance="neutral"
                  id="order_status"
                  name="order_status"
                  onChange={handleChangeSearch}
                  value={searchOrderStatus}
                >
                  <Select.Option label="Status do Pedido" selected value="" />
                  <Select.Option
                    label="Arte em desenvolvimento"
                    value="64e681d64b12c4926ae77d2c"
                  />
                  <Select.Option
                    label="Pagamento Aprovado"
                    value="64e681d64b12c4926ae77d2b"
                  />
                  <Select.Option
                    label="Pedido em produção"
                    value="64e681d64b12c4926ae77d2d"
                  />
                  <Select.Option
                    label="Entregue"
                    value="64e681d64b12c4926ae77d30"
                  />
                  <Select.Option
                    label="Pedido despachado"
                    value="64e681d64b12c4926ae77d2e"
                  />
                  <Select.Option
                    label="Pedido em transito"
                    value="64e681d64b12c4926ae77d2f"
                  />
                </Select>
                <Select
                  appearance="neutral"
                  id="item_status"
                  name="item_status"
                  onChange={handleChangeSearch}
                  value={searchItemStatus}
                >
                  <Select.Option label="Status dos Itens" selected value="" />
                  {Object.entries(order_items_status).map(([value, label]) => (
                    <Select.Option key={value} value={value} label={label} />
                  ))}
                </Select>
                <Button appearance="neutral" onClick={searchList}>
                  Filtrar <Icon color="currentColor" source={<SearchIcon />} />
                </Button>
              </Box>

              {dataList.length || loadingDataList ? (
                <>
                  <Box display="grid" gap="4">
                    <DataList>
                      {loadingDataList ? (
                        <DataList.Row gap="1">
                          <Box display="flex" justifyContent="space-between">
                            <Box display="flex" gap="4" alignItems="center">
                              <Thumbnail.Skeleton width="30px" />
                              <Text.Skeleton />
                            </Box>
                            <Text.Skeleton />
                          </Box>
                          <Box display="flex" justifyContent="space-between">
                            <Text.Skeleton />
                            <Text.Skeleton />
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            gap="2"
                          >
                            <Box display="flex" flexWrap="wrap" gap="2" pt="2">
                              <Tag.Skeleton />
                              <Tag.Skeleton />
                            </Box>
                            <IconButton.Skeleton />
                          </Box>
                        </DataList.Row>
                      ) : (
                        dataList.map((item, index) => (
                          <DataList.Row key={index} gap="1">
                            <Box display="flex" justifyContent="space-between">
                              <Box display="flex" gap="4" alignItems="center">
                                {item.product_image !== null &&
                                item.product_image !== '' ? (
                                  <Thumbnail
                                    src={item.product_image}
                                    alt={item.product_name}
                                    width="30px"
                                  />
                                ) : (
                                  <Thumbnail
                                    alt={item.product_name}
                                    width="30px"
                                  />
                                )}
                                <Text>{item.product_name}</Text>
                                <Tag appearance="primary">
                                  {order_items_status[
                                    item.custom_order_item_status_id
                                  ] || '---'}
                                </Tag>
                              </Box>
                            </Box>

                            <Box
                              display="flex"
                              gap="5"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Box display="grid" gap="2">
                                <Box display="flex" gap="5">
                                  <Box display="flex" gap="2">
                                    <Text fontWeight="bold">Pedido:</Text>
                                    <Text title={`ID: ${item.ns_order_id}`}>
                                      #{item.ns_order_number}
                                    </Text>
                                  </Box>
                                  <Tag appearance="neutral">
                                    {item.custom_status_name}
                                  </Tag>
                                </Box>
                                <Box display="flex" gap="2">
                                  <Text fontWeight="bold">Cliente</Text>
                                  <Text>{item.ns_customer_name}</Text>
                                </Box>
                              </Box>
                              <Tooltip
                                content="Ir para página detalhada do pedido"
                                position="top"
                              >
                                <Link
                                  as="button"
                                  onClick={() => {
                                    push(
                                      `/custom-orders/${
                                        item.custom_orders_id
                                      }${prepareSearchParams()}`,
                                    );
                                  }}
                                  appearance="primary"
                                >
                                  Detalhes do pedido
                                </Link>
                              </Tooltip>
                            </Box>
                          </DataList.Row>
                        ))
                      )}
                    </DataList>
                    {metaPagination !== null ? (
                      <Pagination
                        activePage={metaPagination.page}
                        onPageChange={(page) => {
                          getDataList(page);
                        }}
                        pageCount={metaPagination.pages}
                      />
                    ) : (
                      <></>
                    )}
                  </Box>
                </>
              ) : (
                <Card>
                  <Card.Body>
                    <EmptyMessage
                      title="Nenhum item personalizado"
                      text="Aguarde para receber pedidos com itens personalizados"
                      icon={<DrinkIcon size={32} />}
                      actions={null}
                    />
                  </Card.Body>
                </Card>
              )}
            </Box>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
}

export default CustomOrderItemsListPage;
